import React, { useEffect, useState } from 'react'

import { Skeleton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'

import { useAuth } from '../context/AuthContext'
import { PrsCalculationsResponse } from './PrsResults'
import { enqueueSnackbar } from 'notistack'

type Props = {
  columns: string[]
  isLoading: boolean
  selectedPRSMethod: string
  unweightedPrsResults: PrsCalculationsResponse
  weightedPrsResults: PrsCalculationsResponse
  numberOfGroups: number
}

type Groups = {
  [key: string]: number[]
}

type KruskalWallisMannWhitneyRequest = {
  groups: Groups
}

type KruskalWallisResponse = {
  p_value: number
  posthoc_nemenyi: number[][]
}

type MannWhitneyResponse = {
  p_value: number
  u1: number
}

const ViolinPlotTooltip: React.FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(props.isLoading)
  const [pValue, setPValue] = useState<number | undefined>(undefined)
  const [groupsPValues, setGroupsPValues] = useState<number[][]>([])

  const { backendRequest, logout } = useAuth()

  useEffect(() => {
    if (Object.keys(props.weightedPrsResults).length > 0 && Object.keys(props.unweightedPrsResults).length > 0) {
      setIsLoading(true)
      const getData = async () => {
        let responseStatus = -1
        let kruskalWallisData: KruskalWallisResponse | undefined = undefined
        let mannWhitneyData: MannWhitneyResponse | undefined = undefined

        const requestData: KruskalWallisMannWhitneyRequest = { groups: {} }

        if (props.selectedPRSMethod === 'unweighted_prs') {
          props.unweightedPrsResults.forEach((patientClass) => {
            const groupName = patientClass.patient_class.class_name
            requestData.groups[groupName] = patientClass.patient_prs_results.map((prsResult) => prsResult[props.selectedPRSMethod])
          })
        } else {
          props.weightedPrsResults.forEach((patientClass) => {
            const groupName = patientClass.patient_class.class_name
            requestData.groups[groupName] = patientClass.patient_prs_results.map((prsResult) => prsResult[props.selectedPRSMethod])
          })
        }

        if (props.numberOfGroups == 2) {
          const responseMannWhitney = await backendRequest({
            method: 'POST',
            endpoint: '/api/prs-statistics/mann-whitney',
            requiresAuth: true,
            body: requestData,
          })

          mannWhitneyData = responseMannWhitney.data as MannWhitneyResponse | undefined
          responseStatus = responseMannWhitney.status
        } else {
          const responseKruskalWallis = await backendRequest({
            method: 'POST',
            endpoint: '/api/prs-statistics/kruskal-wallis',
            requiresAuth: true,
            body: requestData,
          })

          kruskalWallisData = responseKruskalWallis.data as KruskalWallisResponse | undefined
          responseStatus = responseKruskalWallis.status
        }

        return {
          responseStatus: responseStatus,
          kruskalWallisData: kruskalWallisData,
          mannWhitneyData: mannWhitneyData,
        }
      }
      getData()
        .then(({ responseStatus: responseStatus, kruskalWallisData: kruskalWallisData, mannWhitneyData: mannWhitneyData }) => {
          if (responseStatus === 200 && kruskalWallisData) {
            setPValue(kruskalWallisData.p_value)
            setGroupsPValues(kruskalWallisData.posthoc_nemenyi)
          } else if (responseStatus === 200 && mannWhitneyData) {
            setPValue(mannWhitneyData.p_value)
            setGroupsPValues([
              [1, mannWhitneyData.p_value],
              [mannWhitneyData.p_value, 1],
            ])
          } else if (responseStatus === 401) {
            logout()
          }
        })
        .catch((error) => {
          enqueueSnackbar(`An error occurred. ${error.response?.data.detail}`, { variant: 'error' })
        })
        .finally(() => setIsLoading(false))
    }
  }, [props.selectedPRSMethod, props.weightedPrsResults, props.unweightedPrsResults])

  return !isLoading ? (
    <Stack spacing={1} mb={2} mt={1} mx={2}>
      <Typography variant='h6' textAlign='left'>
        Description
      </Typography>
      <Typography variant='body1' textAlign='left'>
        P-value: {pValue ? pValue.toFixed(4) : ''}
      </Typography>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell align='center' sx={{ color: 'white' }}>
              P-value across groups
            </TableCell>
            {props.columns.map((column, index) => (
              <TableCell key={`${index}-table-header`} align='right' sx={{ color: 'white' }}>
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {groupsPValues.map((row, index) => (
            <TableRow key={index}>
              <TableCell align='right' sx={{ color: 'white', border: 'none' }}>
                {props.columns[index]}
              </TableCell>
              {row.map((pValue, index) => (
                <TableCell
                  align='right'
                  sx={{ color: 'white', border: 'none', backgroundColor: pValue <= 0.05 ? 'rgba(74, 222, 128, 0.7)' : '' }}
                  key={`${index}-table-row`}
                >
                  {pValue.toFixed(4)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  ) : (
    <Stack spacing={1} mb={2} mt={1} mx={2} width='15rem'>
      <Typography variant='h6' textAlign='left'>
        Description
      </Typography>
      <Skeleton variant='rounded' height='1rem' width='50%' />
      <Skeleton variant='rounded' height='4rem' />
    </Stack>
  )
}

export default ViolinPlotTooltip
